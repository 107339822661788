import React from "react"

interface Props {
  minHeight: string
  bodyOne: string
  bodyTwo?: string
}

export default function ZeroState(props: Props) {
  return (
    <div style={{ minHeight: props.minHeight }} className="bg--gray3 flex-center border-radius-10px mt-2">
      <p className="text-m text-center garamond text--gray8 m-0">
        {props.bodyOne}
        {props.bodyTwo && <br />}
        {props.bodyTwo}
      </p>
    </div>
  )
}
