import React, { useState } from "react"
import isUndefined from "lodash/isUndefined"
import { Badge, Modal, ModalBody } from "reactstrap"

import AssignmentProgress from "./progress"
import Radio from "../common/forms/radio"
import { AssignmentSort, assignmentStats } from "../../hasura/slices/assignment"
import { User_users_by_pk_classrooms, User_users_by_pk_classrooms_assignments } from "../../hasura/queries/types/User"
import { formatDate } from "../../lib/helpers"

// @ts-ignore
import downloadIcon from "../../lib/images/download.svg"

interface Props {
  assignment: User_users_by_pk_classrooms_assignments
  classroom?: User_users_by_pk_classrooms
  close: () => void
  downloadReport: (id: number) => void
}

export default function Report(props: Props) {
  const [sort, setSort] = useState(AssignmentSort.Completed)

  const { assignment, classroom } = props

  const stats = assignmentStats(classroom!, assignment, sort)

  const studentsHaveProgress = stats.some((s) => s.progress > 0)

  return (
    <Modal toggle={props.close} isOpen size="lg" autoFocus={false} centered fade={false}>
      <ModalBody className="p-4 min-height-400px">
        <div className="d-flex align-items-start justify-content-between">
          <div />
          <div className="mb-2">
            <h1 className="header mb-1 text-center">
              <span className="">{assignment.stars ? `${assignment.stars} Stars` : assignment.book?.title} | </span>{" "}
              <span className="regular">{classroom?.display_name}</span>
            </h1>

            <div className="d-flex align-items-center">
              <p className="text-xs text--gray8 m-0">
                {formatDate(assignment.created_at)} to {formatDate(assignment.due_date)}
              </p>

              <Badge className={`ml-3 text-uppercase text--white ${assignment.is_complete ? "bg--primary" : "bg--success"}`}>
                {assignment.is_complete ? "complete" : "in progress"}
              </Badge>
            </div>
          </div>

          <img
            onClick={() => props.downloadReport(assignment.id)}
            className={`icon-s pointer ${studentsHaveProgress ? "" : "hidden"}`}
            src={downloadIcon}
          />
        </div>

        <div>
          {stats.length > 1 && (
            <div className="d-flex justify-content-center">
              <p className="bold text--gray7">Sort</p>

              <div className="ml-3">
                <Radio onClick={() => setSort(AssignmentSort.Alphabetical)} checked={sort === AssignmentSort.Alphabetical} label="Alphabetical" />
                <Radio onClick={() => setSort(AssignmentSort.Completed)} checked={sort === AssignmentSort.Completed} label="Completed" />
                <Radio onClick={() => setSort(AssignmentSort.Uncompleted)} checked={sort === AssignmentSort.Uncompleted} label="Incompleted" />
              </div>
            </div>
          )}

          <div>
            {stats.map(({ display_name, progress, delta }, idx) => (
              <AssignmentProgress
                isBookAssignment={!isUndefined(assignment.book?.id)}
                name={display_name}
                progress={progress}
                goal={assignment.stars}
                delta={delta}
                key={idx}
              />
            ))}
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}
