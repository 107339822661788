import React, { useEffect, useState } from "react"
import Select from "react-select"
import flatten from "lodash/flatten"
import isEqual from "lodash/isEqual"
import { DropdownToggle, DropdownMenu, DropdownItem, Button, UncontrolledDropdown } from "reactstrap"
import isInteger from "lodash/isInteger"
import moment from "moment"
import partition from "lodash/partition"
import { useDispatch, useSelector } from "react-redux"

import AssignmentModal from "../components/assignments/modal"
import Layout from "../components/layout"
import Report from "../components/assignments/report"
import SEO from "../components/seo"
import ZeroState from "../components/common/zeroState"
import { Option } from "../interfaces/option"
import { UserEvent } from "../lib/userEventTypes"
import { User_users_by_pk_classrooms_assignments } from "../hasura/queries/types/User"
import { downloadCsv, nearestMinutes, parseIdFrom, pluralize, postSlackMessage, searchQueryParams, unarchivedClassroomOptions } from "../lib/helpers"
import { insertUserEventAction, userSelector, UserState } from "../hasura/slices/user"

import {
  AssignmentSort,
  assignmentIsComplete,
  assignmentStats,
  createAssignmentClassroomIdAction,
  deleteAssignmentAction,
} from "../hasura/slices/assignment"

// @ts-ignore
import graphIcon from "../lib/images/graph.svg"
// @ts-ignore
import downloadIcon from "../lib/images/download.svg"
// @ts-ignore
import exitIcon from "../lib/images/exit-blue.svg"
// @ts-ignore
import dots from "../lib/images/dots.svg"

const ALL_CLASSES_OPTION: Option = { value: null, label: "All Classes" }

export default function Assignments() {
  const dispatch = useDispatch()

  const [editingAssignment, setEditingAssignment] = useState<User_users_by_pk_classrooms_assignments | undefined>()
  const [classroom, setClassroom] = useState<Option | undefined>(ALL_CLASSES_OPTION)
  const [displayReportId, setDisplayReportId] = useState<number | undefined>()

  const { accessToken, user }: UserState = useSelector(userSelector)

  const options: Option[] = [ALL_CLASSES_OPTION].concat(...unarchivedClassroomOptions(user))

  const assignments = flatten(user?.classrooms.map((c) => c.assignments))
  const filtered = assignments.filter((a) => isEqual(classroom, ALL_CLASSES_OPTION) || classroom?.value === a.classroom_id)
  const [completed, inProgress] = partition(filtered, (f) => f.is_complete)
  const displayAssignment = assignments.find((a) => a.id === displayReportId)

  /*
    Effects
  */

  useEffect(() => {
    const id = parseInt(searchQueryParams("i") || "", 10)
    const classroom = options.find((o) => o.value === classroomFor(id)?.id)
    if (!accessToken || !isInteger(id) || !classroom) return

    setDisplayReportId(id)
    setClassroom(classroom)
  }, [accessToken])

  useEffect(() => {
    if (!accessToken) return

    const event = { user_id: parseIdFrom(accessToken), user_event_type_id: UserEvent.ViewedAssignments }
    dispatch(insertUserEventAction(accessToken, event))
  }, [accessToken])

  /*
    Methods
  */

  const classroomFor = (assignmentId: number) => user?.classrooms.find((c) => c.assignments.some((a) => a.id === assignmentId))

  const downloadReport = (id: number) => {
    const assignment = assignments.find((a) => a.id === id)
    if (!assignment) return

    const classroom = classroomFor(assignment?.id)
    if (!classroom) return

    const filename = `wordcraft-assignment_${classroom.display_name}_${moment().format("l")}.csv`
    const rows = ["name,stars,percentage"].concat(
      ...assignmentStats(classroom!, assignment, AssignmentSort.Alphabetical).map(({ display_name, delta, progress }) =>
        [display_name, delta, `${progress}%`].join(",")
      )
    )

    postSlackMessage(`${user?.display_name} downloaded ${assignment.stars} stars assignment.`)
    downloadCsv(filename, rows)
  }

  const deleteAssignment = (id: number) => dispatch(deleteAssignmentAction(accessToken!, id, user!.id))

  const assignmentsComponent = (assignments: User_users_by_pk_classrooms_assignments[]) =>
    assignments.map((a, idx) => {
      const dueDate = moment(a.due_date)

      const classroom = user?.classrooms.find((c) => c.id === a.classroom_id)

      const completedCount = classroom?.students.filter((s) => assignmentIsComplete(a, s)).length || 0

      return (
        <div className="py-3 d-flex w-100 justify-content-between align-items-center border-bottom" key={idx}>
          <div className="d-flex align-items-center">
            <div
              className={`${
                a.is_complete ? "text--primary" : "text--success"
              } border border--gray4 flex-center flex-column rounded-circle p-2 width-70px height-70px`}
            >
              <p className="m-0 bold text-s nowrap">
                {dueDate.format("MMM").toUpperCase()} {dueDate.get("date")}
              </p>

              <p style={{ marginTop: "-4px" }} className="text-xxxs mb-0 bold">
                {nearestMinutes(15, dueDate).format("h:mmA")}
              </p>
            </div>

            <div className="ml-5 width-400px">
              <p className="text--gray8 text-s m-0">
                {a.book ? `${a.book.title}` : `Earn ${a.stars} stars`} assignment for{" "}
                {user?.classrooms.find((c) => c.id === a.classroom_id)?.display_name}
              </p>
            </div>
          </div>

          <div className="d-flex align-items-center">
            <div onClick={() => setDisplayReportId(a.id)} className="d-flex flex-column pointer position-relative">
              <Button className="width-200px" color="primary">
                View
              </Button>

              <p style={{ marginTop: "42px" }} className="text-xxs text--gray7 position-absolute w-100 text-center">
                {completedCount} of {pluralize("student", classroom?.students.length)} completed
              </p>
            </div>

            <UncontrolledDropdown className="dropdown-menu-dots ml-3" onClick={(e: any) => e.stopPropagation()}>
              <DropdownToggle>
                <img className="icon-s" src={dots} />
              </DropdownToggle>

              <DropdownMenu className="p-2" right>
                <DropdownItem header className="mb-1 p-2 p-0 text-xs bold">
                  More actions
                </DropdownItem>

                {!a.is_complete && (
                  <DropdownItem className="mb-2 p-0" onClick={() => setEditingAssignment(a)}>
                    Edit
                  </DropdownItem>
                )}

                <DropdownItem className="mb-2 p-0" onClick={() => deleteAssignment(a.id)}>
                  Delete
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
      )
    })

  return (
    <Layout>
      <SEO title="Assignments" />

      {displayAssignment && (
        <Report
          assignment={displayAssignment}
          classroom={user?.classrooms.find((c) => c.assignments.some((a) => a.id === displayAssignment.id))}
          close={() => setDisplayReportId(undefined)}
          downloadReport={downloadReport}
        />
      )}

      <div className="w-100 mt-4 max-width-1100px mx-auto">
        <div className="d-flex justify-content-between">
          <h1 className="text-xl bold m-0">Assignments</h1>

          <Button size="lg" onClick={() => dispatch(createAssignmentClassroomIdAction("new"))} color="success">
            Create assignment
          </Button>
        </div>

        <Select
          className="width-250px mt-1"
          options={options}
          placeholder="Select class..."
          value={classroom}
          // @ts-ignore
          onChange={(option: Option) => setClassroom(option)}
        />

        {filtered.length ? (
          <div className="mt-4">
            {inProgress.length > 0 && (
              <div className="my-4">
                <h2 className="text-m bold m-0 text--gray8">In Progress</h2>
                {assignmentsComponent(inProgress)}
              </div>
            )}

            {completed.length > 0 && (
              <div className="my-4">
                <h2 className="text-m bold m-0 text--gray8">Past Assignments</h2>
                {assignmentsComponent(completed)}
              </div>
            )}
          </div>
        ) : (
          <ZeroState bodyOne="No Assignments." minHeight="50vh" />
        )}
      </div>

      {editingAssignment && <AssignmentModal close={() => setEditingAssignment(undefined)} editing={editingAssignment} />}
    </Layout>
  )
}
